import React from "react";
import Icon from "../../../../components/Icon";
import { IUpdateLogList } from "../../../../interface/home";
import { downLoadFileByLink } from "../../../../utils/download";
import styles from "./index.module.scss";

const PluginDownload = (props: { logList: IUpdateLogList[] }) => {
  const { logList } = props;
  return (
    <div className={styles.plugin_download}>
      <div
        className={styles.download_btn}
        onClick={() => {
          downLoadFileByLink(
            `${window.location.origin}/files/${logList[0]?.downloadUrl}`,
            logList[0]?.appName
          );
        }}
      >
        <Icon name="download-cloud" style={{ width: 20, height: 20 }} />
        下载东风台 Chrome 插件
      </div>
      <div className={styles.download_tip}>
        {logList[0]?.updateAt}更新{logList[0]?.version}
      </div>
    </div>
  );
};

export default PluginDownload;
