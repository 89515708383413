import React, { CSSProperties } from "react";
import { Typography } from "antd";
import styles from "./index.module.scss";
import Icon from "../../../../components/Icon";
import { CHROME_DOWNLOAD } from "../../../../project.config";
import { IUpdateLogList } from "../../../../interface/home";
import { downLoadFileByLink } from "../../../../utils/download";

const InstallTipBox = (props: {
  style?: CSSProperties;
  type?: number;
  logList: IUpdateLogList[];
}) => {
  const { style, type, logList } = props;
  return (
    <div className={styles.install_tip_box} style={style}>
      <div style={{ marginBottom: 12 }}>安装前的准备:</div>
      {type === 1 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 4,
            marginBottom: 12,
          }}
        >
          {" "}
          · 需要 <Icon name="chrome" />
          <Typography.Link
            style={{ textDecorationLine: "underline", color: "#3662EC" }}
            href={CHROME_DOWNLOAD}
            target="_blank"
          >
            下载并安装谷歌Chrome浏览器
          </Typography.Link>
        </div>
      )}
      <div>
        {" "}
        · 点击{" "}
        <Typography.Link
          style={{ textDecorationLine: "underline", color: "#3662EC" }}
          onClick={() => {
            downLoadFileByLink(
              `${window.location.origin}/files/${logList[0]?.downloadUrl}`,
              logList[0]?.appName
            );
          }}
        >
          下载插件文件压缩包
        </Typography.Link>{" "}
        并解压
      </div>
    </div>
  );
};

export default InstallTipBox;
