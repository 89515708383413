import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./index.module.scss";
import Success from "./Success";
import Error from "./Error";
import Create from "./Create";
import { getInviteCodeCount } from "../../interface/register";
import { DFT_URL } from "../../project.config";

const Register = () => {
  const [params] = useSearchParams();
  const [step, setStep] = useState<string>("create");

  const handleGetInviteCodeCount = async (inviteCode: string) => {
    const res = await getInviteCodeCount(inviteCode);
    if (res.code === 0) {
      setStep(res.data.availableTimes === 0 ? "error" : "create");
    }
  };

  useEffect(() => {
    const inviteCode = params.getAll("inviteCode")[0];
    if (inviteCode) {
      handleGetInviteCodeCount(inviteCode);
    }
  }, [params]);

  return (
    <div className={styles.register}>
      <div className={styles.slider}>
        <img
          src={require("../../assets/images/banner.png")}
          alt=""
          height="100%"
          onClick={() => window.open(DFT_URL)}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.main}>
          {step === "create" && <Create onCallback={(v) => setStep(v)} />}
          {step === "success" && <Success />}
          {step === "error" && <Error />}
        </div>
      </div>
    </div>
  );
};

export default Register;
