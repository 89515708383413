import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "antd/dist/antd.min.css";
import "./styles/theme.scss";
import App from "./App";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <App />
);
