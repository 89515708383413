import http from "../utils/request";

export interface IUpdateLogData {
  appId: string;
  pageNum: number;
  pageSize: number;
}

export interface IUpdateLogList {
  appId: string;
  appName: string;
  createAt: string;
  description: string;
  downloadUrl: string;
  fileSize: string;
  title: string;
  updateAt: string;
  version: string;
}

// 获取插件版本信息
export function getUpdateLog(data: IUpdateLogData) {
  return http({
    url: "/api/v1/eastWind/listAppVersion",
    method: "POST",
    data,
  });
}
