export const downLoadFileByLink = (url?: string, fileName?: string) => {
  const x = new XMLHttpRequest();
  x.open("GET", url as string, true);
  x.responseType = "blob";
  x.onload = () => {
    const url = window.URL.createObjectURL(x.response);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName as string;
    a.click();
  };
  x.send();
};
