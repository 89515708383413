import React from "react";
import { IUpdateLogList } from "../../../interface/home";
import styles from "./index.module.scss";

const UpdateLog = (props: { logList: IUpdateLogList[] }) => {
  const { logList } = props;

  return (
    <div className={styles.update_log}>
      <div className={styles.update_log_main}>
        <div className={styles.update_log_title}>更新日志</div>
        <div className={styles.update_log_content}>
          {logList.map((i) => (
            <div className={styles.log_item} key={i.version}>
              <div className={styles.log_item_header}>
                <span className={styles.version}>{i.version}</span>
                <span className={styles.size}>{i.fileSize}</span>
                <span className={styles.time}>{i.updateAt}</span>
              </div>
              {i.description.split("\n").map((j) => (
                <div className={styles.desc} key={j}>
                  {j}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UpdateLog;
