// 中文
export const chineseReg: RegExp =
  /^(?:[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0])+$/;

// 手机号码
export const phoneReg: RegExp = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;

// 虚拟手机号码
export const phoneReg1: RegExp = /^(?:\+?86)?1(?:7[01]|6[57])\d{8}$/;

// 包含数字 字母6位数密码
export const passwordReg: RegExp = /^\S*(?=\S{6,})(?=\S*\d)(?=\S*[a-z])\S*$/;

// 中文 数字
export const chineseAndNumberReg: RegExp = /^[\u4e00-\u9fa50-9]+$/;
