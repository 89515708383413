import { useEffect, useRef, useState } from "react";
import { getSMSCode } from "../../../../../../interface/register";
import { ISMSCodeData } from "../../../../../../interface/types";

let count: number = 60;
const useSendCode = () => {
  const [isSend, setIsSend] = useState<boolean>(false);
  const [btnText, setBtnText] = useState<string>("获取语音验证码");
  const [showToast, setShowToast] = useState<boolean>(false);
  const timeId = useRef<NodeJS.Timer>();
  const toastTimeId = useRef<NodeJS.Timer>();

  const sendCode = async (data: ISMSCodeData) => {
    if (timeId.current) clearInterval(timeId.current);
    const res = await getSMSCode(data);
    console.log(res);
    if (res.code === 0) {
      setIsSend(true);
      setShowToast(true);
      toastTimeId.current = setTimeout(() => {
        setShowToast(false);
      }, 1000 * 5);
      timeId.current = setInterval(() => {
        count--;
        if (count <= 0) {
          setIsSend(false);
          setBtnText("获取语音验证码");
          count = 60;
          clearInterval(timeId.current);
        } else {
          setBtnText(`${count}秒后再次获取`);
        }
      }, 1000);
    }
  };

  useEffect(() => {
    return () => {
      clearInterval(timeId.current);
    };
  }, []);

  return {
    btnText,
    isSend,
    showToast,
    sendCode,
  };
};

export default useSendCode;
