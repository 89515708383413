import React, { useState } from "react";
import Sliders from "./Sliders";
import styles from "./index.module.scss";
import { Typography } from "antd";
import Icon from "../../../components/Icon";
import InstallTipBox from "../components/InstallTipBox";
import { IUpdateLogList } from "../../../interface/home";

const PluginIntroduction = (props: {
  logList: IUpdateLogList[];
  onPageScroll: () => void;
}) => {
  const [current, setCurrent] = useState<number>(1);
  const [showTip, setShowTip] = useState<boolean>(false);

  return (
    <div className={styles.plugin_introduction}>
      <div className={styles.plugin_introduction_main}>
        <div className={styles.header}>
          <img
            src={require("../../../assets/images/plugin-introduction-title.png")}
            alt=""
            width="660"
            style={{ marginBottom: 32 }}
          />
          <Sliders current={current} onChange={setCurrent} />
          <Typography.Link
            style={{
              display: "inline-block",
              color: "#3662EC",
              margin: "34px 0",
            }}
            href={require("../../../assets/file/东风台招聘助手操作指南.pdf")}
            download="东风台招聘助手操作指南.pdf"
          >
            <Icon
              name="download"
              style={{
                width: 20,
                height: 20,
                marginRight: 8,
              }}
            />
            下载PDF完整版安装及产品使用说明
          </Typography.Link>

          <img
            src={require("../../../assets/images/plugin-introduction-img.png")}
            alt=""
            className={styles.img}
          />
        </div>
        {current === 1 && (
          <div style={{ position: "relative" }}>
            {showTip && (
              <>
                <InstallTipBox
                  style={{ top: 118, left: 84 }}
                  type={1}
                  logList={props.logList}
                />
                <div className={styles.use_tip}>
                  <Icon
                    name="product-active"
                    style={{ width: 32, height: 32 }}
                  />
                  已经安装成功?
                  <Typography.Link
                    style={{ color: "#3662EC" }}
                    onClick={() => {
                      props.onPageScroll();
                      setCurrent(2);
                    }}
                  >
                    来看看如何使用插件吧!
                  </Typography.Link>
                </div>
              </>
            )}
            <img
              src={require("../../../assets/images/product-desc1.png")}
              alt=""
              width="1280"
              onLoad={() => setShowTip(true)}
            />
          </div>
        )}
        {current === 2 && (
          <img
            src={require("../../../assets/images/product-desc2.png")}
            alt=""
            width="1280"
          />
        )}
      </div>
    </div>
  );
};

export default PluginIntroduction;
