import Register from "../pages/Register";
import Home from "../pages/Home";

export const routers = [
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/",
    element: <Home />,
    children: [
      {
        path: "/:inviteCode",
        component: <Home />,
      },
    ],
  },
];
