import React, { useState, FC } from "react";
import styles from "./index.module.scss";
import Success from "./Success";
import Error from "./Error";
import Create from "./Create";
import { Drawer } from "antd";

interface IRegisterProps {
  visible: boolean;
  onClose: () => void;
}
const Register: FC<IRegisterProps> = (props) => {
  const { visible, onClose } = props;

  const [step, setStep] = useState<string>("create");

  return (
    <Drawer
      open={visible}
      zIndex={1001}
      width="600px"
      destroyOnClose
      onClose={onClose}
      afterOpenChange={(e) => {
        if (!e) setStep("create");
      }}
    >
      <div className={styles.register}>
        <div className={styles.content}>
          <div className={styles.main}>
            {step === "create" && <Create onCallback={(v) => setStep(v)} />}
            {step === "success" && <Success />}
            {step === "error" && <Error />}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default Register;
