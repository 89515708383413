import http from "../utils/request";
import {
  ISMSCodeData,
  IInviteCodeData,
  IRegisterData,
  ILoginData,
} from "./types";

// 获取验证码
export function getSMSCode(data: ISMSCodeData) {
  return http({
    url: "/api/v1/eastWind/getVerificationCode",
    method: "POST",
    data,
  });
}

// 获取注册码
export function getInviteCode(data?: IInviteCodeData) {
  return http({
    url: "/api/v1/eastWind/generateInviteCode",
    method: "POST",
    data,
  });
}

// 获取验证注册条数
export function getInviteCodeCount(inviteCode: string) {
  return http({
    url: `/api/v1/eastWind/getAvailableTimes/${inviteCode}`,
    method: "GET",
  });
}

// 注册
export function onRegister(data: IRegisterData) {
  return http({
    url: "/api/v1/eastWind/register",
    method: "POST",
    data,
  });
}

// 登录
export function onLogin(data: ILoginData) {
  return http({
    url: "/api/v1/eastWind/login",
    method: "POST",
    data,
  });
}

// 校验邀请码
export function checkInviteCode(params: { inviteCode: string }) {
  return http({
    url: "/api/v1/eastWind/checkInviteCode",
    method: "GET",
    params,
  });
}
