import React from "react";
import { IUpdateLogList } from "../../../interface/home";
import PluginDownload from "../components/PluginDownload";
import styles from "./index.module.scss";

const PluginSummary = (props: {
  logList: IUpdateLogList[];
  onRegister: () => void;
}) => {
  return (
    <div className={styles.plugin_summary}>
      <div className={styles.plugin_summary_main}>
        <img
          src={require("../../../assets/images/plugin-summary-banner.png")}
          alt=""
          width="1240"
        />
        <img
          src={require("../../../assets/images/plugin-summary-info.png")}
          alt=""
          width="639"
          className={styles.plugin_summary_info}
        />

        <div className={styles.plugin_summary_btns}>
          <PluginDownload logList={props.logList} />
          <div className={styles.register} onClick={props.onRegister}>
            立即注册, 免费获得候选人电话
          </div>
        </div>
      </div>
    </div>
  );
};

export default PluginSummary;
