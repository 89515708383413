import React, { useState } from "react";
import { IUpdateLogList } from "../../../interface/home";
import InstallTipBox from "../components/InstallTipBox";
import PluginDownload from "../components/PluginDownload";
import styles from "./index.module.scss";

const PluginUpdate = (props: { logList: IUpdateLogList[] }) => {
  const [showTip, setShowTip] = useState<boolean>(false);
  return (
    <div className={styles.plugin_update}>
      <div className={styles.plugin_update_main}>
        <img
          src={require("../../../assets/images/logo3.png")}
          alt=""
          width="120"
        />
        <div className={styles.title}>更新您的东风台招聘助手</div>

        <PluginDownload logList={props.logList} />

        <div style={{ position: "relative", marginTop: 40 }}>
          {showTip && (
            <InstallTipBox
              style={{ top: 129, left: 44 }}
              logList={props.logList}
            />
          )}
          <img
            src={require("../../../assets/images/plugin-update-img.png")}
            alt=""
            width="1280"
            onLoad={() => {
              setShowTip(true);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PluginUpdate;
