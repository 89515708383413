import React from "react";
import Icon from "../../../../components/Icon";
import styles from "./index.module.scss";

const Sliders = (props: { current: number; onChange: (c: number) => void }) => {
  const { current, onChange } = props;

  return (
    <div className={styles.sliders}>
      <span
        className={styles.slider}
        style={{ transform: `translateX(${4 + (current - 1) * 228}px)` }}
      ></span>
      <div className={styles.slider_item} onClick={() => onChange(1)}>
        <Icon
          name={current === 1 ? "setting-active" : "setting-info"}
          style={{ width: 32, height: 32 }}
        />
        <span>插件安装说明</span>
      </div>
      <div className={styles.slider_item} onClick={() => onChange(2)}>
        <Icon
          name={current === 2 ? "product-active" : "product-info"}
          style={{ width: 32, height: 32 }}
        />
        <span>产品功能说明</span>
      </div>
    </div>
  );
};

export default Sliders;
