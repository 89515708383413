import React from "react";
import { Typography } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import styles from "./index.module.scss";

const Success = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <img
        className={styles.img}
        src={require("../../../assets/images/success.png")}
        alt=""
      />
      <Typography.Title level={1} style={{ marginBottom: 12 }}>
        账号创建成功
      </Typography.Title>
      <Typography.Text type="secondary">
        请通过浏览器插件登录"东风台招聘助手"
      </Typography.Text>
      <div className={styles.tips}>
        <InfoCircleFilled
          style={{ color: "#3662EC", padding: 2, marginRight: 8 }}
        />
        <div className={styles.content}>
          请妥善保管您的账号及密码,
          <strong>1 个账号只能同时登录 1 台设备</strong>。
          {/* 若忘记账号或密码，请联系微信客服 xxxxxx。 */}
        </div>
      </div>
    </div>
  );
};

export default Success;
