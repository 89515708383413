import React from "react";
import styles from "./index.module.scss";

const PluginFunction = () => {
  return (
    <div className={styles.plugin_func}>
      <div className={styles.plugin_func_main}>
        <img
          src={require("../../../assets/images/plugin-function-banner.png")}
          alt=""
          width="725"
          className={styles.banner}
        />
        <img
          src={require("../../../assets/images/plugin-function-text.png")}
          alt=""
          width="475"
          className={styles.text}
        />
        <img
          src={require("../../../assets/images/plugin_function_tip.png")}
          alt=""
          width="588"
        />
        <div className={styles.info_text}>据东风台用户累计反馈数据统计: </div>
        <img
          src={require("../../../assets/images/plugin-function-info.png")}
          alt=""
          width="1280"
        />
      </div>
    </div>
  );
};

export default PluginFunction;
