import React, { CSSProperties, FC } from "react";

interface IIcon {
  name: string;
  style?: CSSProperties;
}

const Icon: FC<IIcon> = (props) => {
  const { name, style } = props;

  return (
    <img
      width="16px"
      height="16px"
      src={require(`../../assets/icons/${name}-icon.png`)}
      style={style}
      alt=""
    />
  );
};

export default Icon;
