import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import classnames from "classnames";
import PluginIntroduction from "./PluginIntroduction";
import SideTag from "./components/SideTag";
import UpdateLog from "./UpdateLog";
import styles from "./index.module.scss";
import PluginFunction from "./PluginFunction";
import PluginSummary from "./PluginSummary";
import PluginUpdate from "./PluginUpdate";
import { getUpdateLog, IUpdateLogList } from "../../interface/home";
import { downLoadFileByLink } from "../../utils/download";
import Register from "./components/Register";
import Icon from "../../components/Icon";
import { checkInviteCode } from "../../interface/register";

const Home = () => {
  const [params] = useSearchParams();
  const urlParams = useParams();

  const [active, setActive] = useState<boolean>(false);
  useEffect(() => {
    window.onscroll = () => {
      setActive(window.scrollY > 100);
    };
    return () => {
      window.onscroll = null;
    };
  }, []);

  const onPageScroll = () => {
    let rate = 1;
    if (window.innerWidth <= 1440) rate = 0.85;
    window.scrollTo({ top: 1500 * rate, behavior: "smooth" });
  };

  const [logList, setLogList] = useState<IUpdateLogList[]>([]);
  const handleGetUpdateLog = async () => {
    const res = await getUpdateLog({
      appId: "XFT",
      pageNum: 0,
      pageSize: 20,
    });
    if (res.code === 0) {
      setLogList(res.data.result);
    }
  };

  useEffect(() => {
    handleGetUpdateLog();
  }, []);

  const [visible, setVisible] = useState<boolean>(false);
  const onOpen = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const [isValidInviteCode, setIsValidInviteCode] = useState<boolean>(false);
  const handleCheckInviteCode = useCallback(async () => {
    const res = await checkInviteCode({
      inviteCode: urlParams?.inviteCode!,
    });
    if (res.code === 0) {
      setIsValidInviteCode(res.data);
    }
  }, [urlParams?.inviteCode]);

  useEffect(() => {
    if (urlParams?.inviteCode) {
      handleCheckInviteCode();
    } else {
      setIsValidInviteCode(true);
    }
  }, [urlParams?.inviteCode, handleCheckInviteCode]);

  if (!isValidInviteCode) return null;

  return (
    <div className={styles.home}>
      <header>
        <div className={styles.main}>
          <div className={styles.logo}>
            <img
              src={require("../../assets/images/logo-text.png")}
              alt=""
              height="26"
              style={{ cursor: "pointer" }}
              onClick={() => (window.location.href = "/")}
            />
          </div>
          <div className={styles.btns}>
            <div className={styles.tutorial} onClick={onPageScroll}>
              <Icon name="setting-active" />
              <span style={{ marginLeft: 8, color: "#3662EC" }}>
                插件安装与使用教程
              </span>
            </div>
            <div
              className={classnames(
                styles.download,
                active && styles.download_active
              )}
              onClick={() =>
                downLoadFileByLink(
                  `${window.location.origin}/files/${logList[0]?.downloadUrl}`,
                  logList[0]?.appName
                )
              }
            >
              下载东风台 Chrome 插件
            </div>
            <div
              className={styles.register}
              onClick={() => {
                onOpen();
                // navigate("/register", {
                //   state: "_blank",
                // })
              }}
            >
              限时免费注册
            </div>
          </div>
        </div>
      </header>

      <img
        src={require("../../assets/images/background.png")}
        alt=""
        width="100%"
        className={styles.background}
      />

      {params.getAll("type")[0] === "update" ? (
        <PluginUpdate logList={logList} />
      ) : (
        <>
          <PluginSummary logList={logList} onRegister={onOpen} />
          <div className={styles.slider_down}>
            <div className={styles.slider_down_main}>
              <img
                className={styles.slider_down_img}
                src={require("../../assets/images/slider-down.png")}
                alt=""
                onClick={onPageScroll}
              />
            </div>
          </div>
          <PluginFunction />
          <PluginIntroduction logList={logList} onPageScroll={onPageScroll} />
          <UpdateLog logList={logList} />
        </>
      )}

      <SideTag />

      <footer>
        <div className={styles.main}>
          <div className={styles.info}>
            <div style={{ marginBottom: 30 }}>
              <img
                src={require("../../assets/images/logo-white.png")}
                alt=""
                height="50"
                style={{ marginRight: 20 }}
              />
              <img
                src={require("../../assets/images/logo-text-white.png")}
                alt=""
                width="145"
              />
            </div>
            <div className={styles.desc}>
              我们是一款在 Chrome 浏览器上使用的简历插件，专注解决 RPO
              企业简历困境的共享平台
              <br />
              加入我们，即刻免费获得知名直聘平台上候选人的联系方式
            </div>
            <div className={styles.rights}>
              All Rights Reserved Dongfengtai © 2022
            </div>
          </div>
          <div className={styles.qrcode}>
            <div className={styles.qrcode_container}>
              <img
                src={require("../../assets/images/qrcode.png")}
                alt=""
                width="164"
              />
            </div>

            <div className={styles.tip}>扫码添加官方微信群</div>
          </div>
        </div>
      </footer>

      <Register visible={visible} onClose={onClose} />
    </div>
  );
};

export default Home;
