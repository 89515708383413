import React from "react";
import { Popover } from "antd";
import styles from "./index.module.scss";

const SideTag = () => {
  return (
    <div className={styles.side_tag}>
      <Popover
        placement="left"
        style={{ borderRadius: 12 }}
        content={
          <div style={{ textAlign: "center", padding: "10px 10px 0 10px" }}>
            <img
              src={require("../../../../assets/images/qrcode.png")}
              width="196"
              alt=""
              style={{ marginBottom: 16 }}
            />

            <p style={{ fontSize: 16 }}>扫码添加官方微信群</p>
          </div>
        }
        trigger={["hover"]}
      >
        <div className={styles.wechat}>联系客服</div>
      </Popover>
      <div
        className={styles.up}
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      ></div>
    </div>
  );
};

export default SideTag;
