import { BrowserRouter, Routes, Route } from "react-router-dom";
import { routers } from "./router/config";
import "./App.scss";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {routers.map((i) => (
          <Route path={i.path} element={i.element} key={i.path}>
            {i?.children?.map((j: any) => (
              <Route path={j.path} element={j.element} key={j.path}></Route>
            ))}
          </Route>
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
