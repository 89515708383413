import { useState } from "react";
import { FieldData } from "rc-field-form/lib/interface";

const useFormCanSubmit = (extra?: string) => {
  const [isCanSubmit, setIsCanSubmit] = useState<boolean>(false);
  const onFieldsChange = (
    changedFields: FieldData[],
    allFields: FieldData[]
  ) => {
    setIsCanSubmit(
      () =>
        allFields
          .filter((i) => i.name.toString() !== extra)
          .every((j) => j.value)

      // allFields
      // .filter((i) => i.name.toString() !== extra)
      // .every((j) => j.value && j.errors?.length === 0)
    );
  };

  return {
    isCanSubmit,
    setIsCanSubmit,
    onFieldsChange,
  };
};

export default useFormCanSubmit;
