import React, { FC } from "react";
import { useSearchParams } from "react-router-dom";
import { Typography, Form, Input, Button, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import styles from "./index.module.scss";
import Icon from "../../../components/Icon";
import useSendCode from "./hooks/useSendCode";
import { onRegister } from "../../../interface/register";
import useFormCanSubmit from "../../../hooks/useFormCanSubmit";
import DToast from "../../../components/DToast";
import { chineseReg, phoneReg, phoneReg1 } from "../../../utils/reg";

interface ICreateProps {
  onCallback: (v: string) => void;
}
const Create: FC<ICreateProps> = (props) => {
  const { onCallback } = props;
  const [form] = Form.useForm();
  const { btnText, isSend, showToast, sendCode } = useSendCode();

  const [params] = useSearchParams();
  const { isCanSubmit, onFieldsChange } = useFormCanSubmit();

  const onCreateAccount = async () => {
    const valid = await form.getFieldsValue();
    console.log(valid);
    const res = await onRegister({
      ...valid,
    });
    if (res.code === 0) {
      onCallback("success");
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Typography.Title
        level={3}
        style={{ marginBottom: 40, textAlign: "center", fontSize: 28 }}
      >
        立即注册, 免费获得候选人电话
      </Typography.Title>
      <Form
        size="large"
        validateTrigger={["onChange"]}
        autoComplete="off"
        form={form}
        onFieldsChange={onFieldsChange}
        className={styles.form}
      >
        <Form.Item
          name="companyName"
          rules={[
            { required: true, message: "请输入公司名称" },
            { pattern: chineseReg, message: "格式错误, 公司名称应为中文" },
          ]}
        >
          <Input
            placeholder="公司名称"
            maxLength={20}
            prefix={<Icon name="company" style={{ marginRight: 4 }} />}
          />
        </Form.Item>
        <Form.Item name="name">
          <Input
            placeholder="您的姓名"
            prefix={<Icon name="user" style={{ marginRight: 4 }} />}
          />
        </Form.Item>
        <Form.Item
          name="phone"
          rules={[
            { required: true, message: "请输入手机号码" },
            { pattern: phoneReg, message: "手机号码格式错误" },
            () => ({
              validator(_, value) {
                if (!value || !phoneReg1.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("虚拟手机号无法注册"));
              },
            }),
          ]}
        >
          <Input
            placeholder="手机号码"
            prefix={<Icon name="phone" style={{ marginRight: 4 }} />}
          />
        </Form.Item>
        <Form.Item
          name="verificationCode"
          rules={[
            { required: true, message: "请输入验证码" },
            { len: 4, message: "验证码输入错误" },
          ]}
        >
          <Input
            placeholder="验证码"
            maxLength={4}
            prefix={<Icon name="code" style={{ marginRight: 4 }} />}
            suffix={
              <Button
                type="link"
                size="small"
                style={{ color: "#3662EC" }}
                icon={<Icon name="call" style={{ marginRight: 4 }} />}
                disabled={isSend}
                onClick={() => {
                  sendCode({
                    phone: form.getFieldValue("phone"),
                    opType: 1,
                    inviteCode: params.getAll("inviteCode")[0],
                  });
                }}
              >
                {btnText}
              </Button>
            }
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: "请输入密码" },
            { min: 6, message: "密码不得少于6位" },
            {
              pattern: /^(?=.*[a-zA-Z])(?=.*\d).+$/g,
              message: "密码格式错误，长度不少于6位且同时包含数字+字母",
            },
          ]}
        >
          <Input
            placeholder="设置密码"
            type="password"
            autoComplete="new-password"
            maxLength={50}
            prefix={<Icon name="password" style={{ marginRight: 4 }} />}
            suffix={
              <Tooltip
                autoAdjustOverflow={false}
                title={
                  <>
                    <div>长度至少为6位</div>
                    <div>须同时包含数字+字母</div>
                  </>
                }
              >
                <InfoCircleOutlined style={{ color: "#0256FF" }} />
              </Tooltip>
            }
          />
        </Form.Item>
        <Form.Item
          name="confirm"
          dependencies={["password"]}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("密码不一致"));
              },
            }),
          ]}
        >
          <Input
            placeholder="设置密码"
            autoComplete="new-password"
            type="password"
            maxLength={50}
            prefix={<Icon name="password" style={{ marginRight: 4 }} />}
          />
        </Form.Item>
        <Form.Item>
          <Button
            className={styles.create}
            type="primary"
            style={{
              width: "100%",
              opacity: isCanSubmit ? 1 : 0.5,
              backgroundColor: "#3662EC",
            }}
            onClick={onCreateAccount}
          >
            立即创建
          </Button>
        </Form.Item>
      </Form>
      {showToast && (
        <DToast text="东风台即将给您拨打电话，通过语音播报验证码，请留意接听来电" />
      )}
    </div>
  );
};

export default Create;
