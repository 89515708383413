import React from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Typography } from "antd";
import styles from "./index.module.scss";

const Error = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <ExclamationCircleFilled
        style={{ color: "#FA5151", fontSize: 56, marginBottom: 26 }}
      />
      <Typography.Title level={2} style={{ margin: 0 }}>
        无法再创建账号
      </Typography.Title>
      <Typography.Title level={2} style={{ margin: "0 0 12px" }}>
        此链接可创建账号数量已用尽
      </Typography.Title>
      <Typography.Text type="secondary">
        如有疑问，请扫描下方二维码，添加客服微信咨询
      </Typography.Text>
      <div className={styles.qrcode}>
        <img
          src={require("../../../../../assets/images/qrcode.png")}
          alt=""
          width={112}
        />
        <p>扫码添加客服微信</p>
      </div>
    </div>
  );
};

export default Error;
